import t from './translate'

export const floatToFixedPointStr = (num, decimals = 1) => num != null
  ? `${num.toFixed(decimals)}`
  : ''

export const intToStr = num => `${num}`

export const isNum = n => typeof n === 'number'

const currOpts = {
  style: 'currency',
  currency: 'EUR'
}
const currencyFormatters = {
  en: new Intl.NumberFormat('en-US', currOpts),
  it: new Intl.NumberFormat('it-IT', currOpts),
  fr: new Intl.NumberFormat('fr-FR', currOpts),
  de: new Intl.NumberFormat('de-DE', currOpts),
  es: new Intl.NumberFormat('es-ES', currOpts),
  ru: new Intl.NumberFormat('ru-RU', currOpts),
  cs: new Intl.NumberFormat('cs-CZ', currOpts),
  sv: new Intl.NumberFormat('sv-SE', currOpts)
}
export const eur = (num, lang, tl) => num != null
  ? currencyFormatters[lang].format(num)
  : (tl || t)('common.not_available')

export const inputMaskNumber = (value, decimals) => {
  const num = parseFloat(value)
  return !isNaN(num) ? `${floatToFixedPointStr(num, decimals)}` : ''
}

export const inputMaskInt = value => inputMaskNumber(value, 0)

export const dateToFilename = date => date.toISOString().slice(0, -5).replace(':', '-').replace(':', '-')

export const camelCaseToSnakeCase = camelCaseString => camelCaseString.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const camelCaseToKebabCase = camelCaseString => camelCaseString.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
