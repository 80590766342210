import { objClone, objDeepEqual } from '../lib/object-utils'
import mergerino from 'mergerino'
import throttle from 'lodash.throttle'
import { configuratorDefaults, ConfiguratorValidator } from './configurator-defaults'
import { getAllowedFamilies, getAvailableFamiliesByType } from './configurator-options'
import { env, localStorageKeys } from '../config'
import { createState } from '../lib/meiosis-custom'
import store from '../lib/store'
import t from '../lib/translate'

// This whole approach assumes that the various
// properties of your model have sufficiently complex
// interdependencies that using some combination
// of dependent, merged, LIFTed, and/or combined streams
// isn't helping reduce the complexity of your app.
// Which is to say: updates to the form should probably
// be handled fairly imperatively.
const reducer = (old, now) => {
  const then = objClone(old)
  // RESET LOGIC
  if (now.reset) {
    delete now.reset
    return now
  }

  // CLEAN ERRORS
  if (now.cleanErrors) {
    delete now.cleanErrors
    then.errors = {}
  }

  // Refresh the whole configurator
  let refresh = false

  const newProductType = now.productType || (now.productConfig ? now.productConfig.productType : null)
  const hasChangedProductType = !!newProductType && then.previouslySelectedProductType !== newProductType

  // Respond to macro changes (productType or productConfig)
  if ((now.productType && then.productType !== now.productType) || now.productConfig || (now.ui && now.ui.searchMode)) {
    then.family = {}
    if (now.ui && now.ui.searchMode) {
      // Special case for "New from this" function
      if (now.ui.searchMode === 'newfromthis') {
        now.ui.searchMode = 'byname'
      } else {
        now.productType = null
        now.productConfig = null
        then.tubeSeriesNumber = '-'
      }
    }
    if (now.productConfig) {
      then.productType = now.productConfig.productType
      then.family[now.productConfig.familyCode] = true
      then.fanPercentage = 100
    }
    if (now.productType) {
      now.previouslySelectedProductType = now.productType
      if (!now.productConfig) then.productConfig = null
      then.family = getAvailableFamiliesByType(now.productType)
    }
    if (!then.ui.condition.show[then.condition]) {
      now.condition = Object.keys(then.ui.condition.show).find(k => then.ui.condition.show[k]) || configuratorDefaults.condition
    }
    then.priority = configuratorDefaults.priority
    if (hasChangedProductType) {
      then.fluid.fluidType = configuratorDefaults.fluid.fluidType
    }
    refresh = true
  }
  // if (now.productType === null) {
  //   then.family = {}
  //   refresh = true
  // }
  // Respond to unit of measurement changes
  if (now.unitSystem) refresh = true

  // -------------------------------
  // MERGE UPDATES
  const state = mergerino(then, now)
  // -------------------------------

  // Refresh
  if (refresh) state.key = Date.now()

  // PRODUCT TYPE
  const isACC = state.productType === 'acc'
  const isLC = state.productType === 'lc'
  const isLHP = state.productType === 'lhp'

  // CONFIGURATOR TYPE
  const isSearchModeByName = state.ui.searchMode === 'byname'

  // RANGE
  const families = getAllowedFamilies()
  state.ui.range = state.productType && families[state.productType]
    ? Object.keys(families[state.productType])
      .reduce((acc, curr) => {
        acc[curr] = families[state.productType][curr].some(x => state.family[x])
        return acc
      }, {})
    : {}

  // FAMILY
  const availableFamilies = Object.keys(state.family)
  // 'Ecooler only' condition
  const isEcoolerOnly = !availableFamilies.some(k => k !== 'ecooler' && k !== 'ecooler_container' && state.family[k])
  // 'H/V microchannel only' condition
  const isHVMicrochannelOnly = !availableFamilies.some(k => k !== 'hv_microchannel' && state.family[k])
  // 'Radial only' condition
  const isRadialOrHVCommercialRangeOnly = !availableFamilies.some(k => k !== 'radial' && k !== 'hv_commercial_range' && state.family[k])
  // HSS Evolution condition
  const isHSSEVolutionRange = isLC && availableFamilies.some(k => [
    'combo', 'combo_ulcsa', 'superjumbo', 'superjumbo_ulcsa', 'superjumbo_wall', 'superjumbo_wall_ulcsa', 'wall'
  ].includes(k) && state.family[k])

  // ADIABATIC SYSTEM
  //   (The following rules are overriden in 'search by name' mode by the product config values)
  // Show dry system option - always, except when in 'Ecooler only' condition (see above)
  state.ui.condition.show.drySystem = state.productConfig
    ? state.productConfig.adiabaticSystem.drySystem
    : !isEcoolerOnly
  // Show spray adiabatic system option - always except in 'Radial only' or 'Ecooler only' conditions (see above)
  state.ui.condition.show.sprayAdiabaticSystem = state.productConfig
    ? state.productConfig.adiabaticSystem.sprayAdiabaticSystem
    : !isRadialOrHVCommercialRangeOnly && !isEcoolerOnly
  // Show hybrid spray system option - always except in 'Radial only' condition (see above)
  state.ui.condition.show.hybridSpraySystem = state.productConfig
    ? state.productConfig.adiabaticSystem.hybridSpraySystem
    : !isRadialOrHVCommercialRangeOnly
  // Show HSS Evolution option - only in selected families (see above)
  state.ui.condition.show.hybridSpraySystemEvolution = state.productConfig
    ? state.productConfig.adiabaticSystem.hybridSpraySystemEvolution
    : isHSSEVolutionRange
  // Show industrial adiabatic system for V-SHAPE and ECOOLER families only (no TABLE and OTHER)
  state.ui.condition.show.industrialAdiabaticSystem = state.productConfig
    ? state.productConfig.adiabaticSystem.industrialAdiabaticSystem
    : availableFamilies
      .some(k => k !== 'hv_airflow' &&
        k !== 'hv_airflow_ulcsa' &&
        k !== 'hv_microchannel' &&
        k !== 'radial' &&
        k !== 'hv_commercial_range' &&
        state.family[k])
  // Show industrial adiabatic system with recirculation for V-SHAPE and ECOOLER families only (no TABLE and OTHER)
  state.ui.condition.show.industrialAdiabaticSystemWithRecirculation = state.productConfig
    ? state.productConfig.adiabaticSystem.industrialAdiabaticSystemWithRecirculation
    : state.ui.condition.show.industrialAdiabaticSystem
  state.ui.showPriority = isLHP

  if (!state.ui.condition.show[state.condition]) {
    state.condition = Object.keys(state.ui.condition.show).find(k => state.ui.condition.show[k]) || configuratorDefaults.condition
  }

  // HEAT EXCHANGERS
  // Show round tube option - always, except when ACC and in 'H/V microchannel only' condition (see above)
  state.ui.heatExchangers.show.roundTube = !(isACC && isHVMicrochannelOnly)
  // Show oval tube option only if LC
  state.ui.heatExchangers.show.ovalTube = isLC
  // Show microchannel option only if ACC and 'H/V microchannel' family selected
  state.ui.heatExchangers.show.microchannel = isACC && state.family.hv_microchannel === true

  // AIR
  // Show cool and hot inlet air temperature and relative humidity (inputs and data) - LHP only
  state.ui.air.show.cool = isLHP && state.priority !== 'onlyHeating'
  state.ui.air.show.hot = isLHP && state.priority !== 'onlyCooling'

  // FLUID
  // Set the default refrigerant fluid according to the product type
  if (!state.fluid.fluidType) state.fluid.fluidType = isACC ? 4 : 6 // TODO hardcoded
  // Show gas temperature inputs and data - ACC only
  state.ui.fluid.show.gas = isACC
  // Show cool and hot inlet and outlet water temperature (inputs and data) - LHP only
  state.ui.fluid.show.cool = isLHP && state.priority !== 'onlyHeating'
  state.ui.fluid.show.hot = isLHP && state.priority !== 'onlyCooling'
  // Hide non-compatible refrigerants when only 'H/V microchannel' family is selected
  state.ui.fluid.show.compatibleRefrigerantsOnly = isHVMicrochannelOnly || !!(state.productConfig && state.productConfig.familyCode === 'hv_microchannel')

  // DUTY
  // Show the double circuit option checkbox and second circuit capacity data - ACC only
  state.ui.duty.show.doubleCircuitOption = isACC && (state.productConfig
    ? !['hv_microchannel', 'radial', 'hv_commercial_range'].includes(state.productConfig.familyCode)
    : availableFamilies
      .some(k => k !== 'hv_microchannel' &&
        k !== 'radial' &&
        k !== 'hv_commercial_range' &&
        state.family[k]))
  if (isACC && !state.ui.duty.show.doubleCircuitOption) {
    state.duty.doubleCircuitOption = false
  }
  // Show the water flow input and data - LC/LHP only
  state.ui.duty.show.waterFlow = isLC || isLHP
  // Show max liquid pressure drop field - LC/LHP only
  state.ui.duty.show.maxLiquidPressureDrop = isLC || isLHP
  // Show circuit capacity only if where in search by type and range
  state.ui.duty.show.circuitCapacity = !isSearchModeByName
  // Disable max liquid pressure drop field when a tubeSeriesNumber value is provided
  state.ui.duty.disableMaxLiquidPressureDrop = !state.ui.duty.show.maxLiquidPressureDrop || !state.tubeSeriesNumber || state.tubeSeriesNumber !== '-'
  // Adjust maxLiquidPressureDrop value accordingly
  if (state.ui.duty.disableMaxLiquidPressureDrop) {
    state.duty.maxLiquidPressureDrop = null
  } else if (state.duty.maxLiquidPressureDrop == null) {
    state.duty.maxLiquidPressureDrop = configuratorDefaults.duty.maxLiquidPressureDrop
  }
  // Disable surface margin field when no input temperature or capacity are provided
  state.ui.duty.disableSurfaceMargin = !state.fluid.inputTemperature || !state.fluid.outputTemperature || !state.duty.circuitCapacity || !state.ui.duty.show.circuitCapacity

  // LINKED FIELDS
  // Manage fields linked with physical equation
  // Manage fields linked by type and range
  let linkedFieldsValues = []
  if (isLC) {
    linkedFieldsValues = [
      state.fluid.inputTemperature,
      state.fluid.outputTemperature,
      state.duty.waterFlow,
      ...(isSearchModeByName ? [] : [state.duty.circuitCapacity])
    ]
  } else if (isLHP) {
    const fluidCoolTemperaturesValues = [
      state.fluid.inputTemperatureCool,
      state.fluid.outputTemperatureCool
    ]
    const fluidHotTemperaturesValues = [
      state.fluid.inputTemperatureHot,
      state.fluid.outputTemperatureHot
    ]
    linkedFieldsValues = [
      state.duty.waterFlow,
      ...(state.priority === 'heating' || state.priority === 'onlyHeating' ? fluidHotTemperaturesValues : fluidCoolTemperaturesValues),
      ...(isSearchModeByName ? [] : [state.duty.circuitCapacity])
    ]
  }
  const linkedFieldsLength = linkedFieldsValues.length
  // console.log('linkedFieldsValues', linkedFieldsValues, 'linkedFieldsLength', linkedFieldsLength, linkedFieldsValues.filter(x => x != null && x !== '').length)
  if (linkedFieldsLength && linkedFieldsValues.filter(x => x != null && x !== '').length !== linkedFieldsLength - 1) {
    state.errors.linkedFields = t('configurator.linked_fields_error', { fields: linkedFieldsLength - 1, total: linkedFieldsLength })
  } else {
    delete state.errors.linkedFields
  }

  const isAnyFamilyChecked = Object.values(state.family).some(x => x)
  // Show form (hide it when required data are missing)
  state.ui.showForm = (!isSearchModeByName && state.productType && isAnyFamilyChecked) || !!state.productConfig

  state.ui.showTubeSeriesNumber = !isHVMicrochannelOnly

  // Allow form submit (deny if required data are missing or there are validation errors)
  state.ui.allowSubmit = state.ui.showForm && !Object.keys(state.errors).length
  if (Object.keys(state.errors).length) console.log('CONFIGURATOR ERRORS', state.errors)

  // Show "reset" button for advanced filters when values diverge from defaults
  state.ui.hasAdvancedFiltersDiff = !objDeepEqual({
    dimensions: state.dimensions,
    ventilationNoise: state.ventilationNoise,
    ventilationTech: state.ventilationTech,
    ventilationPowerSource: state.ventilationPowerSource,
    maxPowerConsumption: state.maxPowerConsumption,
    energyEfficiencyClass: state.energyEfficiencyClass
  }, {
    dimensions: configuratorDefaults.dimensions,
    ventilationNoise: configuratorDefaults.ventilationNoise,
    ventilationTech: configuratorDefaults.ventilationTech,
    ventilationPowerSource: configuratorDefaults.ventilationPowerSource,
    maxPowerConsumption: configuratorDefaults.maxPowerConsumption,
    energyEfficiencyClass: configuratorDefaults.energyEfficiencyClass
  })

  const validationErrors = ConfiguratorValidator.validate(mergerino(state, { errors: undefined })) // TODO TEMP, skip validation instead
  if (validationErrors) {
    console.error('CONFIGURATOR VALIDATION ERRORS')
    console.log(JSON.stringify(validationErrors, null, 2))
  }

  return state
}

// Create configurator state
const configuratorState = createState(reducer, configuratorDefaults, localStorageKeys.cs)

// State persistence (on LocalStorage)
configuratorState.map(throttle(state => {
  store.set(localStorageKeys.cs, state)
}, 2000, { leading: false, trailing: true }))

// Debug
if (env.isDevelop) window.configurator = () => configuratorState()

export default configuratorState
