import { customFansIdsOffset } from '../config'
import { formatObjectValues, objClone } from '../lib/object-utils'

export const formatCustomConfiguratorRequest = data => {
  const p = objClone(data)
  const isLTHT = p.productType === 'ltht'

  if (isLTHT) {
    delete p.coils
    formatObjectValues(p.coilsLT)
    formatObjectValues(p.coilsHT)
  } else {
    formatObjectValues(p.coils)
    delete p.coilsLT
    delete p.coilsHT
  }

  const isAirCoolOrHot = p.ui.air.show.cool || p.ui.air.show.hot
  const isFluidCoolOrHot = p.ui.fluid.show.cool || p.ui.fluid.show.hot

  // HEAT EXCHANGERS
  // Object.keys(p.heatExchangers).forEach(k => !p.ui.heatExchangers.show[k] && delete p.heatExchangers[k])

  // AIR
  if (isAirCoolOrHot) {
    delete p.air.inputTemperature
    delete p.air.relativeHumidity
  }
  if (!isAirCoolOrHot || !p.ui.air.show.hot) {
    delete p.air.inputTemperatureHot
    delete p.air.relativeHumidityHot
  }
  if (!isAirCoolOrHot || !p.ui.air.show.cool) {
    delete p.air.inputTemperatureCool
    delete p.air.relativeHumidityCool
  }
  formatObjectValues(p.air)

  // FLUID
  if (isLTHT) {
    delete p.fluid
    formatObjectValues(p.fluidLT)
    formatObjectValues(p.fluidHT)
  } else {
    if (p.ui.fluid.show.gas || isFluidCoolOrHot) {
      delete p.fluid.inputTemperature
      delete p.fluid.outputTemperature
    } else {
      delete p.fluid.condensingTemperature
      delete p.fluid.gasTemperature
      delete p.fluid.subCoolingDeltaTemperature
    }
    if (!isFluidCoolOrHot || !p.ui.fluid.show.hot) {
      delete p.fluid.inputTemperatureHot
      delete p.fluid.outputTemperatureHot
    }
    if (!isFluidCoolOrHot || !p.ui.fluid.show.cool) {
      delete p.fluid.inputTemperatureCool
      delete p.fluid.outputTemperatureCool
    }
    formatObjectValues(p.fluid)
    delete p.fluidLT
    delete p.fluidHT
  }

  // DUTY
  if (isLTHT) {
    delete p.duty
    formatObjectValues(p.dutyLT)
    formatObjectValues(p.dutyHT)
  } else {
    if (!p.ui.duty.show.waterFlow) {
      delete p.duty.waterFlow
    }

    formatObjectValues(p.duty)

    if (p.ui.duty.show.doubleCircuitOption && p.duty.doubleCircuitOption) {
      p.duty.secondCircuitCapacity = p.duty.circuitCapacity
    }

    delete p.dutyLT
    delete p.dutyHT
  }

  if (p.airflow) {
    // AIRFLOW
    p.airflow.flowRate = parseFloat(p.airflow.flowRate)
  } else {
    // FANS
    formatObjectValues(p.fans)
    if (p.fans.fanId > customFansIdsOffset) {
      p.fans.fanId = p.fans.fanId - customFansIdsOffset
      p.fans.isCustomFan = true
    }

    // NOISE
    p.ventilationNoise.distance = parseFloat(p.ventilationNoise.distance)

    // DIMENSIONS
    formatObjectValues(p.dimensions)
  }

  // CLEANUP
  if (p.productConfig) delete p.productConfig
  if (p.productType !== 'lhp') delete p.priority
  delete p.ui
  delete p.errors
  delete p.version
  delete p.key
  delete p.previouslySelectedProductType

  return p
}
