import m from 'mithril'
// import Error from '../views/errors/error'
import Layout from '../views/layout/_layout'
import message from '../views/common/messages/message'
// import { env } from '../config'
import { hasAction, isAuth } from '../models/auth'
import state from '../models/state'
// import { log } from './log'
import t from './translate'

const routeResolver = route => ({
  onmatch: (args, requestedPath) => {
    // Redirect default route to configurator first step
    if (requestedPath === '/') {
      return m.route.set('/configurator-select/1')
    }
    // Redirect auth routes to login if user is not logged in
    if (!route.isPublic && !isAuth()) {
      state.update({ prevPath: requestedPath })
      return m.route.set('/login')
    }

    const currentRoute = requestedPath.split('?')[0] || ''
    const section = currentRoute.split('/')[1] || ''
    args.app = {
      currentRoute,
      isAllowed: (route.actions || []).every(hasAction),
      section
    }
    args.key = args.key || section

    return route.component()
      .then(module => module.default)
      .catch(error => {
        if (error && error.message && error.message.includes('dynamically imported module')) {
          // Reload the page to fetch the updated files
          window.location.reload(true)
        } else {
          console.error(error)
        }
      })
      .finally(() => {
        // Scroll to top at every route change
        if (!args.skipScroll) window.scrollTo(0, 0)
      })
  },

  render: vnode => vnode.attrs.app.isAllowed
    ? vnode.attrs.app.section === 'print'
      ? vnode
      : m(Layout, { ...vnode.attrs }, vnode)
    : m(Layout, { ...vnode.attrs }, [
      message({
        className: 'is-danger',
        icon: 'faExclamationTriangle',
        message: t('common.unauthorized_notice')
      })
    ])
})

export default routeResolver
