const routes = {
  '/': {},
  '/acl-groups': {
    component: () => import('./views/admin/acl-groups.js'),
    isPublic: false,
    actions: ['acl.config']
  },
  '/acl-roles': {
    component: () => import('./views/admin/acl-roles.js'),
    isPublic: false,
    actions: ['acl.config']
  },
  '/admin': {
    component: () => import('./views/admin/admin.js'),
    isPublic: false,
    actions: ['acl.drop_sessions']
  },
  '/changelog': {
    component: () => import('./views/changelog/changelog.js'),
    isPublic: false
  },
  '/configurator-select/1': {
    component: () => import('./views/configurator/wizard-1.js'),
    isPublic: false
  },
  '/configurator-select/2': {
    component: () => import('./views/configurator/wizard-2.js'),
    isPublic: false
  },
  '/configurator-select/3': {
    component: () => import('./views/configurator/wizard-3.js'),
    isPublic: false
  },
  '/configurator-select/4': {
    component: () => import('./views/configurator/wizard-4.js'),
    isPublic: false
  },
  '/configurator-search/1': {
    component: () => import('./views/configurator/wizard-1.js'),
    isPublic: false
  },
  '/configurator-search/2': {
    component: () => import('./views/configurator/wizard-2.js'),
    isPublic: false
  },
  '/configurator-search/3': {
    component: () => import('./views/configurator/wizard-3.js'),
    isPublic: false
  },
  '/configurator-search/4': {
    component: () => import('./views/configurator/wizard-4.js'),
    isPublic: false
  },
  '/coil-costing/1': {
    component: () => import('./views/coil-costing/wizard-1.js'),
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/coil-costing/2': {
    component: () => import('./views/coil-costing/wizard-2.js'),
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/coil-costing/3': {
    component: () => import('./views/coil-costing/wizard-3.js'),
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/coil-costing/4': {
    component: () => import('./views/coil-costing/wizard-4.js'),
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/unit-costing/1': {
    component: () => import('./views/unit-costing/wizard-1.js'),
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/unit-costing/2': {
    component: () => import('./views/unit-costing/wizard-2.js'),
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/unit-costing/3': {
    component: () => import('./views/unit-costing/wizard-3.js'),
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/unit-costing/4': {
    component: () => import('./views/unit-costing/wizard-4.js'),
    isPublic: false,
    actions: ['configurator.costing']
  },
  '/custom-configurator/1': {
    component: () => import('./views/custom-configurator/wizard-1.js'),
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/custom-configurator/2': {
    component: () => import('./views/custom-configurator/wizard-2.js'),
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/custom-configurator/3': {
    component: () => import('./views/custom-configurator/wizard-3.js'),
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/custom-configurator/4': {
    component: () => import('./views/custom-configurator/wizard-4.js'),
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/custom-coils/1': {
    component: () => import('./views/custom-coils/wizard-1.js'),
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/custom-coils/2': {
    component: () => import('./views/custom-coils/wizard-2.js'),
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/custom-coils/3': {
    component: () => import('./views/custom-coils/wizard-3.js'),
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/custom-coils/4': {
    component: () => import('./views/custom-coils/wizard-4.js'),
    isPublic: false,
    actions: ['configurator.custom_products']
  },
  '/customers': {
    component: () => import('./views/customers/customers.js'),
    isPublic: false
  },
  '/customers/:key': {
    component: () => import('./views/customers/customer.js'),
    isPublic: false
  },
  '/customers/:key/edit': {
    component: () => import('./views/customers/customer-edit.js'),
    isPublic: false
  },
  '/email-verification': {
    component: () => import('./views/user/email-verification.js'),
    isPublic: true
  },
  '/energetic-analysis-custom-cities': {
    component: () => import('./views/energetic-analysis-custom/energetic-analysis-custom-cities.js'),
    isPublic: false,
    actions: ['configurator.custom_energetic_analysis']
  },
  '/energetic-analysis-custom-cities/:key': {
    component: () => import('./views/energetic-analysis-custom/energetic-analysis-custom-city.js'),
    isPublic: false,
    actions: ['configurator.custom_energetic_analysis']
  },
  '/login': {
    component: () => import('./views/user/login.js'),
    isPublic: true
  },
  '/maintenance': {
    component: () => import('./views/maintenance/maintenance.js'),
    isPublic: true
  },
  '/new-customer': {
    component: () => import('./views/customers/new-customer.js'),
    isPublic: false
  },
  '/new-energetic-analysis-custom-city': {
    component: () => import('./views/energetic-analysis-custom/new-energetic-analysis-custom-city.js'),
    isPublic: false,
    actions: ['configurator.custom_energetic_analysis']
  },
  '/new-user': {
    component: () => import('./views/users/new-user.js'),
    isPublic: false,
    actions: ['users.create']
  },
  '/offers': {
    component: () => import('./views/offers/offers.js'),
    isPublic: false
  },
  '/offers/:key': {
    component: () => import('./views/offers/offer.js'),
    isPublic: false
  },
  '/offers/:offerId/positions/:key/:productType': {
    component: () => import('./views/offers/offer-position.js'),
    isPublic: false
  },
  '/password-forgot': {
    component: () => import('./views/user/password-forgot.js'),
    isPublic: true
  },
  '/password-reset': {
    component: () => import('./views/user/password-reset.js'),
    isPublic: true
  },
  '/print/energetic-analysis': {
    component: () => import('./views/print/print-energetic-analysis.js'),
    isPublic: false
  },
  '/print/energetic-analysis-redirect': {
    component: () => import('./views/print/print-energetic-analysis-redirect.js'),
    isPublic: true
  },
  '/print/offer': {
    component: () => import('./views/print/print-offer.js'),
    isPublic: false
  },
  '/print/offer-redirect': {
    component: () => import('./views/print/print-offer-redirect.js'),
    isPublic: true
  },
  '/print/position-summary': {
    component: () => import('./views/print/print-position-summary.js'),
    isPublic: false
  },
  '/print/position-summary-redirect': {
    component: () => import('./views/print/print-position-summary-redirect.js'),
    isPublic: true
  },
  '/profile': {
    component: () => import('./views/user/profile.js'),
    isPublic: false
  },
  '/signup': {
    component: () => import('./views/user/signup.js'),
    isPublic: true
  },
  '/signup-completion': {
    component: () => import('./views/user/signup-completion.js'),
    isPublic: true
  },
  '/users': {
    component: () => import('./views/users/users.js'),
    isPublic: false,
    actions: ['users.list']
  },
  '/users/:key': {
    component: () => import('./views/users/user.js'),
    isPublic: false,
    actions: ['users.detail']
  },
  '/users/:key/edit': {
    component: () => import('./views/users/user-edit.js'),
    isPublic: false,
    actions: ['users.edit']
  }
}

export default routes
